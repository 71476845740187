import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

// CONSTANTS
import { STEPS_TO_SELL_CAR_CONTENT } from '@source/pages/SellCar/constants';
import useWindowResize from '@source/hooks/useWindowResize';

const StyledSectionLine = styled.div`
  width: 65px;
  height: 5px;
  background: ${(props) => props?.theme?.color?.primaryBase};
  margin: 0 auto 42px;

  @media screen and (min-width: 768px) {
    margin: 0 auto 30px;
  }

  @media screen and (min-width: 1280px) {
    margin: 0 auto 80px;
  }
`;

const StyledWrapper = styled.div<{ backgroundColor: string; stepCount: number }>`
  padding: 40px 0;
  ${(props) => props.theme.typo.familyGoogle.regular};

  .txt--orange {
    color: ${(props) => props.theme.color.primaryBase};
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    .title-number {
      font-size: 142px;
      line-height: 142px;
    }

    .title-text {
      text-transform: uppercase;
      ${(props) => props.theme.typo.familyGoogle.semiBold};
      color: ${(props) => props.theme.color.onBackgroundMediumEmphasis};

      span {
        white-space: nowrap;
        display: block;
        font-size: 26px;
        line-height: 28px;
        font-weight: 700;

        &.tw-text {
          font-size: 32px;
          line-height: 36px;
        }
      }
    }
  }

  .details {
    width: 290px;
    margin: auto;
    border-top: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
  }

  .step-name {
    width: 50%;
    display: flex;

    &-number {
      width: 24px;
      margin-right: 8px;
      font-size: 30px;
      line-height: 24px;
      letter-spacing: -0.06em;
    }

    &-text {
      width: 96px;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 700;

      .step-title {
        font-size: 14px;
        ${(props) => props.theme.typo.familyGoogle.bold};
        margin-bottom: 0;
      }

      .step-desc {
        letter-spacing: -0.03em;
        font-size: 12px;
        ${(props) => props.theme.typo.familyGoogle.bold};
        letter-spacing: unset;
      }
    }
  }

  .step-image {
    width: 50%;
    text-align: center;

    img {
      max-width: 90%;
      max-height: 80px;
    }
  }

  .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30.5px 7px 22px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      background: ${(props) => props.theme.color.primaryBase};
    }

    &::before {
      top: 0;
      width: 1px;
      height: 100%;
    }

    &::after {
      top: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: translateX(-50%);
    }

    &:last-child::before {
      height: 50%;
    }
  }

  @media screen and (min-width: 576px) {
    .details {
      width: 362px;
    }

    .step-name-text {
      width: 135px;
    }

    .step {
      padding: 30px 0 35px;

      &::after {
        top: 65px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .steps {
      display: flex;
      align-items: center;
      width: 680px;
      margin: 0 auto;
    }

    .title {
      display: block;
      width: 88px;
      padding-right: 12px;

      .title-number {
        font-size: 100px;
        line-height: 100px;
      }

      .title-text {
        letter-spacing: -0.03em;
        text-transform: uppercase;

        span {
          font-size: 18px;
          line-height: 19px;
          font-weight: 600;

          &.tw-text {
            font-size: 26px;
            line-height: 28px;
          }
        }
      }
    }

    .details {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      margin: 0;
      border-top: none;
      border-left: ${(props) => `1px solid ${props.theme.color.primaryBase}`};
    }

    .step-name {
      height: calc(100% - 115px);
      position: relative;
      width: 100%;
      padding: 25px 0 18px 18px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.color.primaryBase};
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -5px;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: ${(props) => props.theme.color.primaryBase};
        transform: translateX(-50%);
      }

      &-text {
        width: 142px;
        min-height: 70px;
        font-size: 14px;
        font-weight: 700;

        .step-desc {
          font-size: 14px;
          ${(props) => props.theme.typo.familyGoogle.semiBold};
        }
      }

      &-number {
        width: 24px;
        margin-right: 3px;
        font-size: 30px;
        line-height: 24px;
        letter-spacing: -0.02em;
      }
    }

    .step-image {
      width: 100%;
      padding: 39px 0 16px;
      text-align: center;

      img {
        margin: 0;
      }
    }

    .step {
      width: ${(props) => `calc(100%/${props.stepCount})`};
      display: block;
      padding: 0;

      &::before,
      &::after {
        content: none;
      }

      &:last-child .step-name::before {
        width: 50%;
      }
    }
  }

  @media screen and (min-width: 992px) {
    padding: 48px 0;

    .steps {
      width: 900px;
    }

    .title {
      display: flex;
      justify-content: center;
      width: 250px;
      padding-right: 6px;

      .title-number {
        font-size: 140px;
        line-height: 200px;
      }

      .title-text {
        padding-right: 10px;

        span {
          font-size: 32px;
          font-weight: 600;
          line-height: 30px;

          &.tw-text {
            font-size: 32px;
            line-height: 36px;
          }
        }
      }
    }

    .step-name {
      height: calc(100% - 104px);
      padding: 24px 0 24px 20px;

      &-text {
        /* width: 170px; */
        min-height: 56px;
        font-weight: 600;
      }
    }

    .step-image {
      padding-top: 28px;
    }
  }

  @media screen and (min-width: 1280px) {
    .steps {
      width: 1150px;
    }

    .title {
      width: 300px;
      padding-right: 15px;

      .title-number {
        font-size: 200px;
        line-height: 200px;
      }

      .title-text {
        span {
          font-weight: 600;
          font-size: 38px;
          line-height: 38px;

          &.tw-text {
            font-size: 46px;
            line-height: 48px;
          }
        }
      }
    }

    .step-name {
      height: calc(100% - 80px);
      padding: 12px 0 24px 22px;

      &-text {
        width: 220px;
        min-height: 43px;
        font-weight: 600;
        font-size: 16px;

        .step-title {
          font-size: 16px;
        }

        .step-desc {
          font-size: 14px;
        }
      }
    }

    .step-image {
      padding-top: 20px;
      padding-bottom: 0;
    }
  }
`;

interface StepsProps {
  id: string;
  title: string;
  desc: string;
  image: string;
}

interface StepsContentProps {
  title?: string;
  description?: string;
  steps?: StepsProps[];
}

interface StepsToSellCarProps {
  hasSectionLine?: boolean;
  backgroundColor?: string;
  stepContent: StepsContentProps;
  countryCode: string;
}

const StepsToSellCar = ({ hasSectionLine, backgroundColor, stepContent = {}, countryCode }: StepsToSellCarProps) => {
  const { steps, description } = stepContent || STEPS_TO_SELL_CAR_CONTENT('') || {};
  const { t } = useTranslation();
  const [width] = useWindowResize();
  const isJP = countryCode === 'jp';

  return (
    <StyledWrapper backgroundColor={backgroundColor || ''} stepCount={steps?.length || 3}>
      <StyledSectionLine className={`${hasSectionLine && 'section-line'}`} />
      <div className="steps">
        <h2 className="title">
          <div className="title-number txt--orange">{steps?.length || 3}</div>
          <div className="title-text">
            <Trans
              defaults={t(description || '')}
              components={{
                span: <span />,
                // Add br for mobile only
                // eslint-disable-next-line react/jsx-no-useless-fragment
                ...(width < 768 || width > 991 ? { jpbr: <br /> } : { jpbr: <></> }),
              }}
            />
          </div>
        </h2>
        <div className="details">
          {steps?.map((step: StepsProps, index: number) => (
            <div key={step.id} className="step">
              <div className="step-name">
                <div className={isJP ? 'step-name-number' : 'step-name-number txt--orange'}>{index + 1}.</div>
                <div className="step-name-text">
                  <h3 className={isJP ? 'step-title' : 'step-title txt--orange'}>{t(step?.title)}</h3>
                  <span className={isJP ? 'step-desc txt--orange' : 'step-desc'}>{t(step?.desc)}</span>
                </div>
              </div>
              <div className="step-image">
                <img src={step.image} alt={t(step?.title)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </StyledWrapper>
  );
};

StepsToSellCar.defaultProps = {
  hasSectionLine: true,
  backgroundColor: 'transparent',
};

export default StepsToSellCar;
